import React from "react"

import Title from 'components/Title'
import Constrain from 'components/Constrain'
import Video from 'components/Video'

import Button from 'components/Button'

import Container from 'components/Container'
import image from 'images/shop-management-video.png'
import { useSiteMetadata } from "../hooks/useSiteMetadata"
import Footer from "components/Footer"
import Header from "components/Header"

import { useTranslation } from 'gatsby-plugin-react-i18next'

export default function Home() {

  const { t } = useTranslation()

  const { title } = useSiteMetadata()

  return (
    <>
    <Header />
    <main>
      <Title 
        title={t(`How do you benefit?`)} 
        subtitle={ t('Increase transparency, reduce human error and get more done') }
        dark 
      />
      <Constrain>
        <Video poster={ image } src='https://autoservice.nyc3.cdn.digitaloceanspaces.com/media/shop-management-video.mp4' />
      </Constrain>

      <Container>

          <h1>{ t(`Build trust with clear communication`) }</h1>

          <h2>{ t(`Centralized messaging`) }</h2>

          <p>
            { t(`Never give out your cell phone number again. Send and receive SMS messages from any of your devices.`) }
          </p>

          <Button to='/benefits/centralized-messaging/'>{ t(`Learn more`) }</Button>

          <h2>{ t(`Customer relationships`) }</h2>

          <p>
            { t(`Measure the satisfaction of your customers.`) }
          </p>
          <Button to='/benefits/customer-relationships/'>{ t(`Learn more`) }</Button>

        </Container>

        <Container>

          <h1>{ t(`Contactless payment options`) }</h1>

          <p>
            { t(`Start accepting online & mobile payments in minutes.`) }
          </p>

          <Button to='/benefits/contactless-payment-options/'>{ t(`Learn more`) }</Button>

        </Container>
        <Container>

          <h1>{ t(`Security and peace of mind`) }</h1>

          <h2>{ t(`Effortless sign in`) }</h2>

          <p>
              { t(`Sign in with just a pin code from your linked devices.`) }
          </p>

          <Button to='/benefits/effortless-sign-in/'>{ t(`Learn more`) }</Button>

          <h2>{ t(`Nothing to upgrade or patch`) }</h2>

          <p>
              { t(`{{ siteTitle }} is always up to date with the latest upgrades and security patches.`, { siteTitle: title }) }
          </p>

          <Button to='/benefits/nothing-to-upgrade-or-patch/'>{ t(`Learn more`) }</Button>

      </Container>
  </main>
  <Footer/>
  </>
  )
}
